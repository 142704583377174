<template>
<div class="ce-content">

    <div class="row ce-row">

        <!-- First col ------------------------>
        <div class="col-md-12">

            <!-- ce-content-inner ------------------------------>
            <div class="ce-content-inner">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="ce-create-client-card-title"><span>Create Client</span></h3>
                        <div class="ce-create-client-sec">
                            <div class="ce-create-client-card">

                                <div class="ce-create-client-card-item-wrap">

                                    <div class="ce-create-client-card-item-wrap-content">
                                        <h3 class="ce-create-client-card-item-wrap-content-title">Name
                                        </h3>
                                        <div class="ce-create-client-card-item-wrap-input">
                                            <input type="text" class="form-control" placeholder="Enter Name" v-model="client.name">
                                        </div>
                                    </div>

                                    <div class="ce-create-client-card-item-wrap-content">
                                        <h3 class="ce-create-client-card-item-wrap-content-title">Domain
                                        </h3>
                                        <div class="ce-create-client-card-item-wrap-input ce-domain">
                                            <input type="text" class="form-control" placeholder="Enter Domain url" v-model="client.domain">
                                        </div>
                                    </div>

                                    <div class="ce-create-indepentant-client">
                                        <input type="checkbox" class="form-control" v-model="client.dependent">Independent
                                    </div>

                                    <div class="ce-additional-url-cover" :class="{'ce-sso-dashno-nav': client.dependent}" v-if="client.dependent">
                                      <div class="ce-additional-url-inner">
                                        <div class="ce-create-client-card-item-wrap-content">
                                            <h3 class="ce-create-client-card-item-wrap-content-title">Login Url
                                            </h3>
                                            <div class="ce-create-client-card-item-wrap-input ce-domain">
                                                <input type="text" class="form-control" placeholder="Enter Login url" v-model="client.login_url">
                                            </div>
                                        </div>
                                        <div class="ce-create-client-card-item-wrap-content">
                                            <h3 class="ce-create-client-card-item-wrap-content-title">Logout Url
                                            </h3>
                                            <div class="ce-create-client-card-item-wrap-input ce-domain">
                                                <input type="text" class="form-control" placeholder="Enter Logout url" v-model="client.logout_url">
                                            </div>
                                        </div>
                                      </div>
                                    </div>

                                </div>

                                <div v-on:click="createClient(client)" class="ce-create-client-btn-wrap">
                                    <button class="btn ce-btn-create-client-submit">
                                        Submit
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ce-content-inner End ------------------------>

        </div>
        <!-- First col End -------------------------------->

    </div>
</div>
</template>

<script>
import Api from '../includes/Api';
import {
    mapState
} from 'vuex';
export default {
    data() {
        return {
            client: {
                name: '',
                domain: '',
                dependent: false,
                login_url: '',
                logout_url: ''
            }
        }
    },
    computed: mapState('User', ['user']),
    methods: {
        createClient(data) {
            let root = this;
            Api.post('/create-client', {
                data: data,
                user_id: root.user.id
            }).then(() => {
                root.$router.push('/client/list');
            });
        }
    }
}
</script>
